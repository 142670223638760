exports.linkResolver = function linkResolver(cta) {

        if(cta.link_type === "Document" && cta.type === 'page'){
                return '/r/' + cta.uid;
            }else if(cta.link_type === "Media" ||cta.link_type === "Web"  ){
                return cta.url;
            }else{
                return '/';
            }
         
        }
        

