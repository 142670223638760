import React, { useState } from "react"
import LinkWrap from '../common-components/LinkWrap'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

/* eslint-disable */

const HeaderMenuItem = ({ linkItem, n, type }) => {


    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

    const [open, setOpened] = useState(false);
    const toggleOpen = () => setOpened(!open);
    config.autoAddCss = false;

    return (

        <li key={n} className={hovered || open ? "HeaderMenuListItem ShowSub" : "HeaderMenuListItem"}
            onMouseEnter={type === 'desktop' ? toggleHover : null}
            onMouseLeave={type === 'desktop' ? toggleHover : null}
            onClick={type === 'mobile' ? toggleOpen : null}
        >
            {linkItem.link.uid ?
                <LinkWrap link={linkItem.link} >{linkItem.name}</LinkWrap> :
                <div className="fauxAnchor">
                    {linkItem.name} {linkItem.sub_menu &&
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            size="xs"
                            className="DownArrow"
                        />}
                </div>
            }
            {linkItem.sub_menu.document &&
                <ul className="HeaderMenuSubMenu">
                    {linkItem.sub_menu.document.data.links.map((subLinkItem, k) => (
                        <li key={k} >
                            <LinkWrap link={subLinkItem.link} >{subLinkItem.name}</LinkWrap>
                        </li>
                    ))}


                </ul>}
        </li>
    )

}
export default HeaderMenuItem;