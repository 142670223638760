import React, { useLayoutEffect, useState } from 'react'
import { StaticQuery, graphql } from "gatsby"
import HeaderLogo from './HeaderLogo'
import HeaderMenu from './HeaderMenu'
import HeaderMobMenu from './HeaderMobMenu'
import _ from 'lodash'

const Header = ({data}) => {

  const [ scrolled, setScrolled ] = useState()
 
  var hasScrolled = scrolled;
  useLayoutEffect(() => {
    const handleScroll = () => { 
      if (window.pageYOffset > 10) {
        setScrolled(true)
      }

      
    }
    window.addEventListener('scroll', _.debounce(handleScroll,10))
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])



  return(
  <header className={hasScrolled && "Scrolled"}>
    <HeaderLogo />
    <HeaderMenu data={data}/>
    <HeaderMobMenu data={data} />

    <div className="Donate"><a href="https://australasian-diving-safety-foundation.raisely.com/">Donate</a></div>
  </header>
)
}




export default () => (
  <StaticQuery
    query={graphql`
    query MainMenuQuery {
      allPrismicMainMenu {
        nodes {
          data {
            links {
              link {
                uid
                type
                link_type
              }
              name
              sub_menu {
                uid
                type
                link_type
                document {
                  ... on PrismicSubMenu {
                    id
                    data {
                      links {
                        link {
                          uid
                          target
                          type
                          link_type
                          slug
                        }
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
      
      
    `}
    render={(data) => (
      <Header data={data.allPrismicMainMenu.nodes[0].data.links} />
    )}
  />
)
