/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import "../styles/index.scss"


const Layout = ({ children }) => {
 
  return (
    <div className="Layout">
        <Header />
          <main>{children}</main>
        <Footer  />
    </div>
  )
}

export default Layout
