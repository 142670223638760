import React from "react"
import logo from "../../../static/img/adsf-logo.png"
import {Link} from 'gatsby'

const HeaderLogo = () =>(
    <div className="HeaderLogo">
        <Link to="/"><img src={logo} alt="ADSF"/></Link>
    </div>
)

export default HeaderLogo