import React from "react"
import HeaderMenuItem from './HeaderMenuItem'

const HeaderMenu = ({ data }) => (
    <div className="HeaderMenu">
      <ul className="HeaderMenuList">
        {data && data.map((linkItem, n) => (
          <HeaderMenuItem linkItem={linkItem} n={n} type="desktop"/>
        ))
        }

      </ul>
    </div>
  )

export default HeaderMenu