import React from "react"
import FooterMenu from "./FooterMenu"
import FooterNotes from "./FooterNotes"
const Footer = () => (
  <footer>
    <FooterMenu />
    <FooterNotes />
  </footer>
)

export default Footer
