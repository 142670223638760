import { Link } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver' 
import React from 'react'

const LinkWrap = ({link,children,onMouseEnter,onMouseLeave}) =>{

    return(
        link && link.link_type === "Web"  ?
        <a href={link.raw.url}
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}>{children}</a> :
      
        <Link to={link && linkResolver(link)} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}>{children}</Link> 
    )
} 

export default LinkWrap