import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import HeaderMenuItem from './HeaderMenuItem'

const HeaderMobMenu = ({ data }) => {

    const [open, setOpened] = useState(false);
    const toggleOpen = () => setOpened(!open);

    return (
        <div className="HeaderMobMenu">
            <FontAwesomeIcon
                icon={faBars}
                size="2x"
                className="MobMenuBurger"
                onClick={toggleOpen}
            />

            <ul className={open ? "HeaderMobMenuList Show": "HeaderMobMenuList"}>
                {data && data.map((linkItem, n) => (
                    <HeaderMenuItem linkItem={linkItem} n={n} type="mobile" />
                ))
                }
            </ul>
        </div>

    )
}



export default HeaderMobMenu;