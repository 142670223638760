import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LinkWrap from '../common-components/LinkWrap'
const FooterMenu = ({data}) =>(

    <div class="FooterMenu">
        <ul className="FooterMenuList">
        {data && data.map((linkItem, n) => (
            <li key={n}>
              <LinkWrap link={linkItem.link} >{linkItem.name}</LinkWrap>
            </li>
        ))
        }
        
        </ul>
    </div>
)



export default () => (
    <StaticQuery
      query={graphql`
      query FooterMenuQuery {
        allPrismicFooterMenu {
          nodes {
            data {
              links {
                name
                link {
                  uid
                  raw
                  type
                  link_type
                  target
                  slug
                }
              }
            }
          }
        }
      }
      
    `}
      render={(data) => (
        <FooterMenu data={data.allPrismicFooterMenu.nodes[0].data.links} />
      )}
    />
  )